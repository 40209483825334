const getters = {
  entities_gifts: ({ entities }) => {
    return entities.filter((entity) => {
      return entity.type === 'gift'
    })
  },
  entity_gift: ({ entities }) => (id = null) => {
    let value = {
      recipient: null,
      item: '',
      giftType: '',
      type: 'gift',
      message: '',
    }
    if (id) {
      const gifts = entities.filter((entity) => {
        return entity.type === 'gift' && entity.id === id
      })
      const gift = gifts[0] || {}
      value = {
        ...value,
        ...gift,
      }
    }

    return value
  },
}

const actions = {
}

const mutations = {
}

export default {
  getters,
  actions,
  mutations
};
