import { vic_epa_required } from "../../../../config/poaRequiredFields"
import store from "../../../index"
import entityArrayComplete from "./utils"

const getters = {
  vic_epa_is_valid: ({ vault }) => {
    const doc = vault.find((o) => o.id === "poaVicEpa") || {}
    const people = store.getters["account/entities_people"]
    // if changed, also change in /portal/src/components/areas/manageCustomers/PoaManagement.vue
    if (!doc.attorneys?.length || !doc.commencement || !doc.matters)
      return false
    if (
      !entityArrayComplete(
        doc.attorneys.map((a) => a.entity),
        people,
        vic_epa_required
      )
    )
      return false
    return true
  },
  poa_vic_epa: ({ vault }) => {
    const doc = vault.find((o) => o.id === "poaVicEpa") || {}

    const options = {
      appointmentOptions: ["joint", "several", "joint-and-several", "majority"],
    }

    const defaultValues = {
      attorneys: [],
      attorneysAppointment: null,
      matters: null,
      specifiedMatters: null,
      commencement: null,
      differentImmediately: null,
      differentIncapacity: null,
      differentSpecifiedOccasion: null,
      differentSpecifiedMatters: null,
      conflictTransactions: null,
      gifts: null,
      maintenanceOfDependants: null,
      paymentToAttorneys: null,
      additionalConditions: null,
      revocation: null,
      signatureAssistance: null,
      hasPriorEpa: null,
    }

    return {
      attorneys: doc.attorneys ? doc.attorneys : defaultValues.attorneys,
      attorneysAppointment:
        doc.attorneysAppointment &&
        options.appointmentOptions.includes(doc.attorneysAppointment)
          ? doc.attorneysAppointment
          : defaultValues.attorneysAppointment,
      matters: doc.matters ? doc.matters : defaultValues.matters,
      specifiedMatters: doc.specifiedMatters
        ? doc.specifiedMatters
        : defaultValues.specifiedMatters,
      commencement: doc.commencement
        ? doc.commencement
        : defaultValues.commencement,
      differentImmediately: doc.differentImmediately
        ? doc.differentImmediately
        : defaultValues.differentImmediately,
      differentIncapacity: doc.differentIncapacity
        ? doc.differentIncapacity
        : defaultValues.differentIncapacity,
      differentSpecifiedOccasion: doc.differentSpecifiedOccasion
        ? doc.differentSpecifiedOccasion
        : defaultValues.differentSpecifiedOccasion,
      differentSpecifiedMatters: doc.differentSpecifiedMatters
        ? doc.differentSpecifiedMatters
        : defaultValues.differentSpecifiedMatters,
      conflictTransactions: doc.conflictTransactions
        ? doc.conflictTransactions
        : defaultValues.conflictTransactions,
      gifts: doc.gifts ? doc.gifts : defaultValues.gifts,
      maintenanceOfDependants: doc.maintenanceOfDependants
        ? doc.maintenanceOfDependants
        : defaultValues.maintenanceOfDependants,
      paymentToAttorneys: doc.paymentToAttorneys
        ? doc.paymentToAttorneys
        : defaultValues.paymentToAttorneys,
      additionalConditions: doc.additionalConditions
        ? doc.additionalConditions
        : defaultValues.additionalConditions,
      revocation: doc.revocation ? doc.revocation : defaultValues.revocation,
      hasPriorEpa:
        doc.hasPriorEpa === true || doc.hasPriorEpa === false
          ? doc.hasPriorEpa
          : defaultValues.hasPriorEpa,
      signatureAssistance: doc.signatureAssistance,
    }
  },
}

const actions = {}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
