import access from './user/access'
import data from './user/data'
import funeral from './user/funeral'
import profile from './user/profile'

const getters = {
  ...profile.getters,
  ...data.getters,
  ...funeral.getters,
  ...access.getters,
}

const actions = {
  ...profile.actions,
  ...data.actions,
  ...funeral.actions,
  ...access.actions,
}

const mutations = {
  ...profile.mutations,
  ...data.mutations,
  ...funeral.mutations,
  ...access.mutations,
}

export default {
  getters,
  actions,
  mutations
}
