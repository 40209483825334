<template>
  <v-container fluid class="w-container-wide mb-12">
    <v-row v-if="title" class="title-row">
      <v-col cols="12" md="7" offset-md="0">
        <div class="w-page-title">{{ title }}</div>
      </v-col>
      <v-col v-if="sidebarTitle" cols="12" md="4" offset-md="1" class="d-none d-md-flex">
        <div class="w-title mt-auto">{{ sidebarTitle }}</div>
      </v-col>
    </v-row>
    <v-row class="content-row">
      <v-col cols="12" md="7">
        <slot></slot>
      </v-col>
      <v-col cols="12" md="4" offset-md="1">
        <slot name="sidebar"></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'WGrid',
  props: {
    title: {
      default: null
    },
    sidebarTitle: {
      default: null
    }
  }
}
</script>

<style lang="scss">
  .title-row {
    margin-bottom: 32px;
    @media only screen and (max-width: 599px) {
      margin-bottom: 16px;
    }
  }
</style>

