import auth from './modules/auth'
import entities from './modules/entities'
import lists from './modules/lists'
import poa from './modules/poa'
import user from './modules/user'
import vault from './modules/vault'
import will from './modules/will'

export default {
  namespaced: true,

  state: {
    auth: {},
    data: {},
    entities: [],
    vault: [],
    lists: lists.state,
    registrationData: {},
    reset: () => {},
  },

  getters: {
    ...user.getters,
    ...poa.getters,
    ...auth.getters,
    ...will.getters,
    ...entities.getters,
    ...lists.getters,
    ...vault.getters
  },

  mutations: {
    ...user.mutations,
    ...poa.mutations,
    ...auth.mutations,
    ...will.mutations,
    ...entities.mutations,
    ...vault.mutations
  },

  actions: {
    ...user.actions,
    ...poa.actions,
    ...auth.actions,
    ...will.actions,
    ...entities.actions,
    ...vault.actions
  }
}
