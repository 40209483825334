
const state = {
  relationshipStatus: [
    {
      label: 'Single',
      text: 'Single, separated, divorced or widowed',
      partner: false,
      key: 'single',
    },
    {
      label: 'Married',
      text: 'Married or engaged',
      partner: true,
      key: 'married',
    },
    {
      label: 'Defacto',
      text: 'Living as a couple on a genuine domestic basis',
      partner: true,
      key: 'defacto',
    },
  ]
}

const getters = {
}

const actions = {
}

const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations
};
