import { db, serverTimestamp } from '../../../plugins/firebase'
import charities from './entities/charities'
import gifts from './entities/gifts'
import guardians from './entities/guardians'
import messages from './entities/messages'
import people from './entities/people'
import pets from './entities/pets'

const getters = {
  ...messages.getters,
  ...pets.getters,
  ...gifts.getters,
  ...people.getters,
  ...charities.getters,
  ...guardians.getters,
  entity: (state) => (id) => {
    if (id === 'ausUnity') {
      return {
        type: 'company',
        name: 'Australian Unity Trustees',
        address: 'Level 15, 271 Spring Street, Melbourne, Victoria'
      }
    }
    return (
      state.entities.filter((entity) => {
        return entity.id === id
      })[0] || {}
    )
  }
}

const actions = {
  ...messages.actions,
  ...pets.actions,
  ...gifts.actions,
  ...people.actions,
  ...charities.actions,
  ...guardians.actions,
  async delete_entity({ getters }, id) {
    const { uid } = getters.auth_user
    return await db
      .collection('usersV2')
      .doc(uid)
      .collection('entities')
      .doc(id)
      .delete()
  },
  async save_entity({ getters }, { id, entity, type }) {
    const { uid } = getters.auth_user
    const docId =
      id || db.collection('usersV2').doc(uid).collection('entities').doc().id
    const docRef = db
      .collection('usersV2')
      .doc(uid)
      .collection('entities')
      .doc(docId)

    const data = {
      ...entity,
      ...(type && { type: type })
    }

    if (!id) {
      data.createdAt = serverTimestamp
    }

    await docRef.set(data, { merge: true })

    return docId
  }
}

const mutations = {
  ...messages.mutations,
  ...pets.mutations,
  ...gifts.mutations,
  ...people.mutations,
  ...charities.mutations,
  ...guardians.mutations
}

export default {
  getters,
  actions,
  mutations
}
