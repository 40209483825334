const state = {
  liabilityTypes: [
    {
      key: 'credit-cards',
      active: true,
      label: 'Credit Cards',
      description: 'Amex, Mastercard, Visa, etc.',
      icon: 'mdi-credit-card-multiple-outline'
    },
    {
      key: 'mortgages',
      active: true,
      label: 'Mortgages',
      description: 'Home Loans, Investment Loans, etc.',
      icon: 'mdi-home-analytics'
    },
    {
      key: 'personal-loans',
      active: true,
      label: 'Personal Loans',
      description: 'Car Loans, Study Loans, etc.',
      icon: 'mdi-account-credit-card-outline'
    },
    {
      key: 'other-liabilities',
      active: true,
      label: 'Other',
      description: 'Any other liabilities',
      icon: 'mdi-credit-card-outline'
    }
  ]
}

const getters = {}

const actions = {}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
