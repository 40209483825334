const getters = {
  entities_guardians: ({ entities }) => {
    return entities.filter((entity) => {
      return (
        entity.type === "person" && entity.guardians && entity.guardians.length
      )
    })
  },
}

const actions = {}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
