import appSettings from "./modules/appSettings"
import appStatus from "./modules/appStatus"
import dataCharities from "./modules/dataCharities"
import dataPartnerPurchase from "./modules/dataPartnerPurchase"
import dataPricing from "./modules/dataPricing"
import dataReferrer from "./modules/dataReferrer"
import userMenu from "./modules/userMenu"
export default {
  namespaced: true,

  state: {
    userMenu: false,
    appSettings: null,
    userMenuAlert: {}, // { type: '', message: '' }
    charities: null,
    referrer: {},
    pricing: null,
    discountCode: null, // { code, type, price }
    promoCodes: null,
    dataLoaded: false,
    pageLoaded: false,
    message: {}, // { type: '', text: '' }
    faq: null, // { question: '', answer: '' }
    partnerPurchase: null,
  },

  getters: {
    ...userMenu.getters,
    ...appSettings.getters,
    ...appStatus.getters,
    ...dataCharities.getters,
    ...dataReferrer.getters,
    ...dataPricing.getters,
    ...dataPartnerPurchase.getters,
  },

  mutations: {
    ...userMenu.mutations,
    ...appSettings.mutations,
    ...appStatus.mutations,
    ...dataCharities.mutations,
    ...dataReferrer.mutations,
    ...dataPricing.mutations,
    ...dataPartnerPurchase.mutations,
    reset: () => {},
  },

  actions: {
    ...userMenu.actions,
    ...appSettings.actions,
    ...appStatus.actions,
    ...dataCharities.actions,
    ...dataReferrer.actions,
    ...dataPricing.actions,
    ...dataPartnerPurchase.actions,
  },
}
