const referrerTags = [
  {
    q: "r_id",
    key: "id",
  },
  {
    q: "r_token",
    key: "token",
  },
  {
    q: "r_source",
    key: "source",
  },
  {
    q: "r_campaign",
    key: "campaign",
  },
  {
    q: "r_content",
    key: "content",
  },
]

const anonFields = ["partner", "children", "pets", "gender", "state"]

export { referrerTags, anonFields }
