import Vue from 'vue'

const getters = {
  user_menu: (state) => {
    return Boolean(state.userMenu)
  },
  user_menu_alert(state) {
    return state.userMenuAlert
  }
}

const actions = {
}

const mutations = {
  SET_USER_MENU(state, val) {
    Vue.set(state, 'userMenu', Boolean(val))
  },
  SET_USER_MENU_ALERT(state, val) {
    Vue.set(state, 'userMenuAlert', val)
  }
}

export default {
  getters,
  actions,
  mutations
}
