<template>
  <svg height="100%" class="couple-icon" viewBox="0 0 20 20">
    <path d="M15.7143 8.45982C17.6339 8.45982 19.1741 6.91964 19.1741 5C19.1741 3.10268 17.6339 1.54018 15.7143 1.54018C13.817 1.54018 12.2545 3.10268 12.2545 5C12.2545 6.91964 13.817 8.45982 15.7143 8.45982ZM15.7143 10C13.3482 10 11.4286 11.808 11.4286 14.0402V20H20V14.0402C20 11.808 18.0804 10 15.7143 10ZM5.35714 7.5C7.43304 7.5 9.10714 5.82589 9.10714 3.75C9.10714 1.67411 7.43304 0 5.35714 0C3.28125 0 1.60714 1.67411 1.60714 3.75C1.60714 5.82589 3.28125 7.5 5.35714 7.5ZM5.35714 9.19643C2.38839 9.19643 0 11.4955 0 14.2857V19.9777H10.7143V14.2857C10.7143 11.4955 8.32589 9.19643 5.35714 9.19643Z" />
  </svg>
</template>

<script>

export default {
  name: 'DoneIcon'
}
</script>

<style lang="scss">
  .couple-icon {
    width: auto;
    path {
      fill: currentColor
    }
  }
</style>
