<template>
  <svg height="100%" class="calendar-icon" viewBox="0 0 21 19" fill="none">
    <path d="M6.4 2.7H1V8.1M6.4 2.7V0M6.4 2.7H14.5M14.5 2.7H19.9V8.1M14.5 2.7V0M1 8.1V18H19.9V8.1M1 8.1H19.9" />
  </svg>

</template>

<script>

export default {
  name: 'DoneIcon'
}
</script>

<style lang="scss">
  .calendar-icon {
    width: auto;
    path {
      stroke: currentColor;
    }
  }
</style>
