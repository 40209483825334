import { sa_epa_required } from "../../../../config/poaRequiredFields"
import store from "../../../index"
import entityArrayComplete from "./utils"

const getters = {
  sa_epa_is_valid: ({ vault }) => {
    const doc = vault.find((o) => o.id === "poaSaEpa") || {}
    const people = store.getters["account/entities_people"]
    // if changed, also change in /portal/src/components/areas/manageCustomers/PoaManagement.vue
    if (!doc.donees?.length || !doc.commencement) return false
    if (!entityArrayComplete(doc.donees, people, sa_epa_required)) return false
    return true
  },
  poa_sa_epa: ({ vault }) => {
    const doc = vault.find((o) => o.id === "poaSaEpa") || {}

    const options = {
      appointmentOptions: ["jointly", "jointly-and-severally"],
      commencementOptions: ["notwithstanding", "incapacity"],
    }

    const defaultValues = {
      donees: [],
      appointment: null,
      commencement: null,
      exclusions: null,
    }

    return {
      donees: doc.donees ? doc.donees : defaultValues.donees,
      appointment:
        doc.appointment && options.appointmentOptions.includes(doc.appointment)
          ? doc.appointment
          : defaultValues.appointment,
      commencement:
        doc.commencement &&
        options.commencementOptions.includes(doc.commencement)
          ? doc.commencement
          : defaultValues.commencement,
      exclusions: doc.exclusions ? doc.exclusions : defaultValues.exclusions,
    }
  },
}

const actions = {}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
