// if changed, also change in /portal/src/components/areas/manageCustomers/PoaManagement.vue
function allRequiredFieldsComplete(entity, required_fields) {
  return required_fields.every((field) => {
    return field === "dob"
      ? entity.dob && entity.dob.day && entity.dob.month && entity.dob.year
      : entity[field] && entity[field].trim().length
  })
}

// if changed, also change in /portal/src/components/areas/manageCustomers/PoaManagement.vue
export default function entityArrayComplete(
  array,
  entities_people,
  required_fields
) {
  return array.every((g) => {
    const entity = entities_people.find((p) => p.id === g)
    return entity && allRequiredFieldsComplete(entity, required_fields)
  })
}
