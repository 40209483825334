import Vue from "vue"

import { settingsRef } from "@/plugins/firebase"

const getters = {
  app_settings: ({ appSettings }) => {
    return appSettings || {}
  },
  prepaid_ebook: (_, { app_settings }) => {
    return app_settings.prepaidEbook || {}
  },
  poa_states: (_, { app_settings }) => {
    return app_settings.poa?.states || []
  },
  credit_card_requirement: (_, { app_settings }) => {
    return app_settings.creditCardRequirement || {}
  },
  price_structure: (_, { app_settings }) => {
    let { addOnDiscount, defaultSubscription, poa, will, subscription } =
      app_settings.pricing || {}

    return {
      addOnDiscount: addOnDiscount || 50,
      defaultSubscription: defaultSubscription || "plan_01",
      poa: {
        default: poa?.default || 99,
        partner: poa?.partner || 49,
        reactivate: poa?.reactivate || 49,
      },
      will: {
        default: will?.default || 159,
        partner: will?.partner || 79,
        reactivate: will?.reactivate || 79,
      },
      subscription: {
        plan_01: subscription.plan_01 || 14,
        plan_02: subscription.plan_02 || 15,
      },
    }
  },
}

const actions = {
  async load_app_settings({ commit }) {
    const doc = await settingsRef.get()

    if (doc.exists) {
      commit("SET_APP_SETTINGS", doc.data())
    }
  },
}

const mutations = {
  SET_APP_SETTINGS(state, data) {
    Vue.set(state, "appSettings", data)
  },
}

export default {
  getters,
  actions,
  mutations,
}
