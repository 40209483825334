const state = {
  assetTypes: [
    {
      key: 'bank-account',
      active: true,
      label: 'Bank Account',
      description: 'Saving, Offsets, Term Deposits, etc.',
      icon: 'mdi-bank-outline'
    },
    {
      key: 'financial-asset',
      active: true,
      label: 'Financial Asset',
      description: 'Superannuation, Shares, Crypto, etc.',
      icon: 'mdi-finance'
    },
    {
      key: 'legal-interest',
      active: true,
      label: 'Legal Interest',
      description: 'Company Interests, Trusts, etc.',
      icon: 'mdi-cash-multiple'
    },
    {
      key: 'property',
      active: true,
      label: 'Property',
      description: 'Apartments, Car Spots, Houses, Lands etc.',
      icon: 'mdi-home-city-outline'
    },
    {
      key: 'valuable',
      active: true,
      label: 'Valuable',
      description: 'Cars, Jewellery, Art, etc.',
      icon: 'mdi-wallet-outline'
    },
    {
      key: 'other-assets',
      active: true,
      label: 'Other',
      description: 'Any other assets',
      icon: 'mdi-wallet-travel'
    }
  ]
}

const getters = {}

const actions = {}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
