import { db } from "@/plugins/firebase"

import store from "../../index"
import actEpa from "./poa/actEpa"
import nswEg from "./poa/nswEg"
import nswEpa from "./poa/nswEpa"
import ntApp from "./poa/ntApp"
import qldEpa from "./poa/qldEpa"
import saAcd from "./poa/saAcd"
import saEpa from "./poa/saEpa"
import tasEg from "./poa/tasEg"
import tasEpa from "./poa/tasEpa"
import vicEpa from "./poa/vicEpa"
import vicMtdm from "./poa/vicMtdm"
import waEpa from "./poa/waEpa"
import waEpg from "./poa/waEpg"

const stateDocs = {
  act: [
    {
      name: "Enduring Power of Attorney",
      documentDir: "actEpa",
      documentName: "poaActEpa",
      storeName: "poa_act_epa",
      validityGetter: "act_epa_is_valid",
    },
  ],
  nsw: [
    {
      name: "Enduring Power of Attorney",
      documentDir: "nswEpa",
      documentName: "poaNswEpa",
      storeName: "poa_nsw_epa",
      validityGetter: "nsw_epa_is_valid",
    },
    {
      name: "Enduring Guardianship",
      documentDir: "nswEg",
      documentName: "poaNswEg",
      storeName: "poa_nsw_eg",
      validityGetter: "nsw_eg_is_valid",
    },
  ],
  nt: [
    {
      name: "Advance Personal Plan",
      documentDir: "ntApp",
      documentName: "poaNtApp",
      storeName: "poa_nt_app",
      validityGetter: "nt_app_is_valid",
    },
  ],
  qld: [
    {
      name: "Enduring Power of Attorney",
      documentDir: "qldEpa",
      documentName: "poaQldEpa",
      storeName: "poa_qld_epa",
      validityGetter: "qld_epa_is_valid",
    },
  ],
  sa: [
    {
      name: "Enduring Power of Attorney",
      documentDir: "saEpa",
      documentName: "poaSaEpa",
      storeName: "poa_sa_epa",
      validityGetter: "sa_epa_is_valid",
    },
    {
      name: "Advance Care Directive",
      documentDir: "saAcd",
      documentName: "poaSaAcd",
      storeName: "poa_sa_acd",
      validityGetter: "sa_acd_is_valid",
    },
  ],
  tas: [
    {
      name: "Enduring Power of Attorney",
      documentDir: "tasEpa",
      documentName: "poaTasEpa",
      storeName: "poa_tas_epa",
      validityGetter: "tas_epa_is_valid",
    },
    {
      name: "Enduring Guardianship",
      documentDir: "tasEg",
      documentName: "poaTasEg",
      storeName: "poa_tas_eg",
      validityGetter: "tas_eg_is_valid",
    },
  ],
  vic: [
    {
      name: "Enduring Power of Attorney",
      documentDir: "vicEpa",
      documentName: "poaVicEpa",
      validityGetter: "vic_epa_is_valid",
      storeName: "poa_vic_epa",
    },
    {
      name: "Medical Treatment Decision Maker",
      documentDir: "vicMtdm",
      documentName: "poaVicMtdm",
      validityGetter: "vic_mtdm_is_valid",
      storeName: "poa_vic_mtdm",
    },
  ],
  wa: [
    {
      name: "Enduring Power of Attorney",
      documentDir: "waEpa",
      documentName: "poaWaEpa",
      validityGetter: "wa_epa_is_valid",
      storeName: "poa_wa_epa",
    },
    {
      name: "Enduring Power of Guardianship",
      documentDir: "waEpg",
      documentName: "poaWaEpg",
      validityGetter: "wa_epg_is_valid",
      storeName: "poa_wa_epg",
    },
  ],
}

const getters = {
  stateDocs: () => stateDocs,
  docsToDownload: () => {
    const user_state = store.getters["account/user_address"].value.state
    const docs = []
    if (stateDocs[user_state])
      stateDocs[user_state].forEach((stateDoc) =>
        docs.push({
          name: stateDoc.name,
          isValid: store.getters[`account/${stateDoc.validityGetter}`],
          documentDir: stateDoc.documentDir,
          toDownload: store.getters[`account/${stateDoc.validityGetter}`],
          generating: false,
          downloaded: false,
          downloadFailed: false,
          token: "",
        })
      )
    return docs
  },
  validityOfDocsForState: () => {
    const user_state = store.getters["account/user_address"].value.state
    const validities = []
    if (stateDocs[user_state])
      stateDocs[user_state].forEach((stateDoc) => {
        const isValid = store.getters[`account/${stateDoc.validityGetter}`]
        validities.push({
          documentName: stateDoc.documentName,
          isValid: isValid,
        })
      })
    return validities
  },
  aboutYouComplete: () => {
    const occupationComplete =
      store.getters["account/user_address"].value.state === "nsw" ||
      store.getters["account/user_address"].value.state === "tas"
        ? store.getters["account/user_occupation"].isComplete
        : true
    return (
      store.getters["account/user_name"].isComplete &&
      store.getters["account/user_phone"].isComplete &&
      store.getters["account/user_address"].isComplete &&
      store.getters["account/user_dob"].isComplete &&
      occupationComplete
    )
  },
  // used for checking if the user has a document saved that will be deleted when
  // they change their state
  existingDocs: ({ vault }) => {
    const user_state = store.getters["account/user_address"].value.state
    const docsThatExist = []
    if (stateDocs[user_state])
      stateDocs[user_state].forEach((stateDoc) => {
        if (vault.find((o) => o.id === stateDoc.documentName))
          docsThatExist.push(stateDoc.documentName)
      })
    return docsThatExist
  },
  ...actEpa.getters,
  ...nswEg.getters,
  ...nswEpa.getters,
  ...ntApp.getters,
  ...qldEpa.getters,
  ...saAcd.getters,
  ...saEpa.getters,
  ...tasEg.getters,
  ...tasEpa.getters,
  ...vicEpa.getters,
  ...vicMtdm.getters,
  ...waEpa.getters,
  ...waEpg.getters,
}

const actions = {
  ...actEpa.actions,
  ...nswEg.actions,
  ...nswEpa.actions,
  ...ntApp.actions,
  ...qldEpa.actions,
  ...saAcd.actions,
  ...saEpa.actions,
  ...tasEg.actions,
  ...tasEpa.actions,
  ...vicEpa.actions,
  ...vicMtdm.actions,
  ...waEpa.actions,
  ...waEpg.actions,
  async save_poa({ getters }, { id, data }) {
    const { uid } = getters.auth_user
    const docRef = db.collection("usersV2").doc(uid).collection("vault").doc(id)
    let keys = Object.keys(data)
    return await docRef.set(data, { mergeFields: keys })
  },
  async delete_state_poas({ getters }, { state }) {
    const { uid } = getters.auth_user
    stateDocs[state].forEach(async (doc) => {
      const docRef = db
        .collection("usersV2")
        .doc(uid)
        .collection("vault")
        .doc(doc.documentName)
      await docRef.delete()
    })
  },
}

const mutations = {
  ...actEpa.mutations,
  ...nswEg.mutations,
  ...nswEpa.mutations,
  ...ntApp.mutations,
  ...qldEpa.mutations,
  ...saAcd.mutations,
  ...saEpa.mutations,
  ...tasEg.mutations,
  ...tasEpa.mutations,
  ...vicEpa.mutations,
  ...vicMtdm.mutations,
  ...waEpa.mutations,
  ...waEpg.mutations,
}

export default {
  getters,
  actions,
  mutations,
}
