const getters = {
  user_name: ({ data }) => {
    const name = data?.name || {}
    const value = {
      first: name.first?.trim() || "",
      middle: name.middle?.trim() || "",
      last: name.last?.trim() || "",
    }

    return {
      value: value,
      displayFull: [value.first, value.middle, value.last]
        .filter(Boolean)
        .join(" "),
      isComplete: !!value.first && !!value.last,
    }
  },
  user_dob: ({ data }, getters) => {
    const { day, month, year } = data?.dob || {}
    const monthItem = Number(month)
      ? getters.lists_group_option("months", Number(month))
      : {}

    return {
      value: {
        day: Number(day),
        month: Number(month),
        year: Number(year),
      },
      displayDate: monthItem.label ? `${day} ${monthItem.label} ${year}` : null,
      isComplete: !!day && !!month && !!year,
    }
  },
  user_address: ({ data }, getters) => {
    let address = data?.address || {}
    if (typeof address === "string") {
      address = addressToObject(address)
    }

    address = {
      street: address.street || "",
      suburb: address.suburb || "",
      state: address.state || "",
      postcode: address.postcode || "",
      country: "Australia",
    }

    let auState = null
    if (address.state) {
      auState = getters.lists_group_option("auStates", address.state)
    }
    if (!auState) {
      address.state = ""
    }

    let isComplete =
      address.street.length &&
      address.suburb.length &&
      address.state.length &&
      /^[0-9]{4}$/.test(address.postcode)

    return {
      displayAddress: isComplete
        ? `${address.street}, ${address.suburb}, ${auState.label} ${address.postcode}, ${address.country}`
        : "",
      value: address,
      isComplete: isComplete,
    }
  },
  user_phone: ({ data }) => {
    const phone = data?.phone || ""

    return {
      value: phone,
      isMobile: true,
      isComplete: Boolean(data?.phone),
    }
  },
  user_partner: ({ data }, getters) => {
    let statusKey = data.relationshipStatus || null
    const statusItem = statusKey
      ? getters.lists_group_option("relationshipStatus", statusKey)
      : null
    let partnerId = data.partnerId || null
    const value = {
      relationshipStatus: statusItem ? statusKey : null,
      partnerId: statusItem?.partner ? partnerId : null,
    }

    return {
      value: value,
      ...(statusItem?.label && { displayStatus: statusItem.label }),
      ...(value.partnerId && {
        partnerName: getters.entity_person(value.partnerId).name,
      }),
      isComplete:
        value.relationshipStatus && (value.partnerId || !statusItem.partner),
      optionItem: statusItem,
    }
  },
  user_alternate_email: ({ data }) => {
    const alternateEmail = data?.alternateEmail || ""

    return {
      value: alternateEmail,
      isComplete: Boolean(data?.alternateEmail),
    }
  },
  user_occupation: ({ data }) => {
    const occupation = data?.occupation || ""

    return {
      value: occupation,
      isComplete: Boolean(data?.occupation),
    }
  },
  user_profile_complete: (_, getters) => {
    return (
      getters.user_name.isComplete &&
      getters.user_dob.isComplete &&
      getters.user_address.isComplete
    )
  },
}

const actions = {}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}

function addressFormatState(val) {
  let key = val.toLowerCase().replace(/[^a-z]/gi, "")

  if (key[0] === "v") {
    return "vic"
  }
  if (key[0] === "q") {
    return "qld"
  }
  if (key[0] === "s") {
    return "sa"
  }
  if (key[0] === "t") {
    return "tas"
  }
  if (key[0] === "w") {
    return "wa"
  }
  if (key[0] === "a") {
    return "act"
  }
  if (key[0] === "n") {
    if (["s", "e"].includes(key[1])) {
      return "nsw"
    }
    if (["t", "o"].includes(key[1])) {
      return "nt"
    }
  }

  return null
}

function addressToObject(addressStr) {
  let addressObj = {
    street: null,
    suburb: null,
    state: null,
    postcode: null,
    country: null,
  }
  let address = addressStr.split(",")
  if (address.length === 5) {
    let [street, city, state, code] = address
    addressObj = {
      street: street.trim(),
      suburb: city.trim(),
      state: addressFormatState(state) || state,
      postcode: code.trim(),
      country: "Australia",
    }
  } else if (address.length === 6) {
    let [unit, street, city, state, code] = address
    street = `${unit.trim()} ${street.trim()}`
    addressObj = {
      street: street.trim(),
      suburb: city.trim(),
      state: addressFormatState(state) || state,
      postcode: code.trim(),
      country: "Australia",
    }
  }

  return addressObj
}
