const getters = {
  will_estate: ({ data }, getters) => {
    const beneficiaries = data?.beneficiaries || {}

    let value = {}

    Object.keys(beneficiaries).forEach(k => {
      let backup = beneficiaries[k].backupOption ? getters.lists_group_option('backupBeneficiaries', beneficiaries[k].backupOption) : null
      value[k] = {
        share: Number(beneficiaries[k].share) || 0,
        backupOption: backup?.active ? backup.key : null,
        backupEntities: backup?.active && backup.requiresEntities ? beneficiaries[k].backupEntities || [] : [],
      }
    })

    return {
      value: value,
    }
  },
}

const actions = {
}

const mutations = {
}

export default {
  getters,
  actions,
  mutations
};
