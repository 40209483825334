<template>
<svg x="0px" y="0px" height="100%" class="medical-icon"
viewBox="0 0 464.015 464.015" style="enable-background:new 0 0 464.015 464.015;" xml:space="preserve">
<g>
<g>
<path d="M154.726,236.863c5.713-1.183,11.682-3.775,17.313-7.84c16.191-11.712,23.377-30.943,16.034-42.975
c-7.984-13.088-29.824-12.208-46.607,2.687c-16.049,13.857-19.473,28.336-19.473,43.553c0,10.752,3.439,29.951,24.447,46.624
c9.919-7.664,21.744-13.969,35.167-18.528l0.178-0.065C171.471,256.021,158.027,247.538,154.726,236.863z M152.136,199.855
c0-4.496,3.582-8.111,8-8.111c4.414,0,8,3.632,8,8.128c0,4.496-3.586,8.111-8,8.111
C155.719,207.983,152.136,204.351,152.136,199.855z"/>
</g>
</g>
<g>
<g>
<path d="M342.311,232.304c-0.048-15.233-3.456-29.712-19.519-43.569c-16.77-14.88-38.625-15.792-46.61-2.687
c-7.328,12.032-0.16,31.264,16.031,42.975c5.633,4.065,11.602,6.656,17.313,7.84c-1.984,6.416-8.016,12.944-17.215,18.448
c-12.65,7.55-19.483,8.484-100.418,35.36c-29.951,10.176-47.774,30.399-47.774,54.096c0,16.88,8.623,29.904,20.606,39.441
c7.537-7.761,16.946-14.496,27.938-19.889c-12.656-6.399-16.561-11.248-16.561-19.552c0-13.706,27.396-21.542,40.034-25.712
v34.689l32-14.273v-20.4c12.658,4.192,39.984,12.018,39.984,25.696c0,22.755-65.158,36.174-92.881,55.296
c-13.455,9.328-20.69,21.185-20.69,35.12c0,8,0.93,17.92,14.977,27.776c3.983,3.182,9.517-1.466,6.93-5.937
c-9.282-18.576,0.574-27.855,19.711-35.888v26.848c0,8.832,7.168,16,16,16c8.832,0,16-7.168,16-16V421.15
c18.383,7.712,29.953,18.608,19.664,35.872c-2.55,4.385,2.88,9.158,6.912,5.937c13.6-10.305,14.672-19.137,14.672-27.568
c0-12.975-7.103-25.567-20.576-35.216c27.233-9.84,51.328-26.4,51.328-55.376c0-20.016-12.718-37.552-34.703-48.656
c10.27-3.696,15.438-6.176,22.302-10.271C337.831,267.903,342.311,244.592,342.311,232.304z M304.136,207.983
c-4.418,0-8-3.632-8-8.128c0-4.496,3.582-8.128,8-8.128c4.414,0,8,3.632,8,8.128C312.136,204.351,308.551,207.983,304.136,207.983
z"/>
</g>
</g>
<g>
<g>
<path d="M456.007,32.031H346.431c-21.496,0-37.627,9.502-51.504,23.38C279.851,70.487,272.012,104,248.007,104V59.562
c9.526-5.543,16-15.746,16-27.562c0-17.673-14.326-32-32-32c-17.674,0-32,14.327-32,32c0,11.815,6.474,22.018,16,27.562v190.685
c9.875-3.15,20.713-6.621,32-10.246v-80.145c6.942-3.483,12.543-9.114,15.978-16.083c5.565,9.512,16.145,16.258,27.938,16.258
c11.961,0,22.266-6.641,27.756-16.36c18.744,18.745,53.584,19.106,72.328,0.362l-27.19-27.19l12.473,5.167
c24.492,10.145,52.57-1.486,62.717-25.978l-38.627-16C438.8,80.031,456.007,56.55,456.007,32.031z"/>
</g>
</g>
<g>
<g>
<path d="M169.087,55.411c-13.877-13.878-30.008-23.38-51.504-23.38H8.007c0,24.518,17.207,48,54.627,48l-38.627,16
c10.146,24.492,38.225,36.122,62.717,25.978l12.473-5.167l-27.19,27.19c18.744,18.745,53.584,18.384,72.328-0.362
c5.49,9.72,15.795,16.361,27.756,16.361c0.118,0,0.236-0.001,0.354-0.002c15.634-0.178,27.584-14.3,27.584-29.934v-32.19
C187.418,87.558,180.195,66.519,169.087,55.411z"/>
</g>
</g>
</svg>
</template>

<script>
  export default {
    name: 'MedicalIcon'
  }
</script>

<style lang="scss">
  .medical-icon {
    width: auto;
    path {
      stroke: currentColor
    }
  }
</style>
