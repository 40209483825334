const state = {
  auStates: [
    {
      key: 'act',
      active: true,
      label: 'Australian Capital Territory',
      labelShort: 'ACT'
    },
    {
      key: 'nsw',
      active: true,
      label: 'New South Wales',
      labelShort: 'NSW'
    },
    {
      key: 'nt',
      active: true,
      label: 'Northern Territory',
      labelShort: 'NT'
    },
    {
      key: 'qld',
      active: true,
      label: 'Queensland',
      labelShort: 'Qld'
    },
    {
      key: 'sa',
      active: true,
      label: 'South Australia',
      labelShort: 'SA'
    },
    {
      key: 'tas',
      active: true,
      label: 'Tasmania',
      labelShort: 'Tas'
    },
    {
      key: 'vic',
      active: true,
      label: 'Victoria',
      labelShort: 'Vic'
    },
    {
      key: 'wa',
      active: true,
      label: 'Western Australia',
      labelShort: 'WA'
    }
  ]
}

const getters = {}

const actions = {}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
