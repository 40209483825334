import Vue from "vue"

import { db } from "../../../plugins/firebase"

const getters = {
  referrer_data: ({ referrer }) => {
    return referrer || null
  },
  referrer_beneficiary_doc: ({ referrer }) => {
    return referrer?.settings?.showAsBeneficiary ? referrer : null
  },
  referrer_discount_price: ({ referrer }) => {
    let discount = referrer?.discount || {}
    let price = parseInt(discount.price)
    return isNaN(price) ? null : price
  },
  referrer_discount: ({ referrer }) => {
    return referrer?.discount
      ? {
          will: parseInt(referrer.discount.price),
          type: "referrer",
          name: referrer.name,
          id: referrer.id,
        }
      : {}
  },
}

const actions = {
  async load_user_referrer({ commit, rootState }) {
    let ref = rootState.account.data?.meta?.referrer || {}
    let refId = ref.id || null

    if (refId) {
      let doc = await db.collection("referrers").doc(refId).get()

      if (doc.exists) {
        commit("SET_REFERRER", { ...doc.data(), id: refId })
      }
      return true
    }

    return true
  },
}

const mutations = {
  SET_REFERRER(state, referrer) {
    Vue.set(state, "referrer", referrer)
  },
}

export default {
  getters,
  actions,
  mutations,
}
