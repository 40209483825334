<template>
  <svg height="100%" class="tick-icon" viewBox="0 0 14 12" fill="none">
    <path d="M0.933594 5.8127L4.85423 9.73333L13.0876 1.5" stroke-width="2"/>
  </svg>
</template>

<script>

  export default {
    name: 'TickIcon'
  }
</script>

<style lang="scss">
  .tick-icon {
    width: auto;
    path {
      stroke: currentColor
    }
  }
</style>
