import { wa_epg_required } from "../../../../config/poaRequiredFields"
import store from "../../../index"
import entityArrayComplete from "./utils"

const getters = {
  wa_epg_is_valid: ({ vault }) => {
    const doc = vault.find((o) => o.id === "poaWaEpg") || {}
    const people = store.getters["account/entities_people"]
    // if changed, also change in /portal/src/components/areas/manageCustomers/PoaManagement.vue
    if (!doc.guardians?.length) return false
    if (!entityArrayComplete(doc.guardians, people, wa_epg_required))
      return false
    if (
      doc.substituteGuardians?.length &&
      !entityArrayComplete(
        doc.substituteGuardians.map((s) => s.entity),
        people,
        wa_epg_required
      )
    )
      return false
    if (doc.advanceHealthDirective === null) return false
    if (
      !(
        doc.functionsAuthorisedAll ||
        doc.functions?.livingSituation ||
        doc.functions?.livingWith ||
        doc.functions?.medicalTreatment ||
        doc.functions?.educationTraining ||
        doc.functions?.associating ||
        doc.functions?.legalProceedings ||
        doc.functions?.supportServices ||
        doc.functions?.seekingInformation ||
        doc.functions?.other.length !== 0
      )
    )
      return false
    return true
  },
  poa_wa_epg: ({ vault }) => {
    const doc = vault.find((o) => o.id === "poaWaEpg") || {}

    const options = {
      appointmentOptions: ["jointly", "jointly-severally"],
    }

    const defaultValues = {
      guardians: [],
      substituteGuardians: [],
      substituteGuardiansCircumstances: null,
      appointmentTermination: null,
      functionsAuthorisedAll: null,
      functions: {
        livingSituation: false,
        livingWith: false,
        medicalTreatment: false,
        educationTraining: false,
        associating: false,
        legalProceedings: false,
        supportServices: false,
        seekingInformation: false,
        other: [],
      },
      circumstances: null,
      directions: null,
      advanceHealthDirective: null,
    }

    return {
      guardians: doc.guardians ? doc.guardians : defaultValues.guardians,
      guardiansAppointment:
        doc.guardiansAppointment &&
        options.appointmentOptions.includes(doc.guardiansAppointment)
          ? doc.guardiansAppointment
          : defaultValues.guardiansAppointment,
      substituteGuardians: doc.substituteGuardians
        ? doc.substituteGuardians
        : defaultValues.substituteGuardians,
      substituteGuardiansCircumstances: doc.substituteGuardiansCircumstances
        ? doc.substituteGuardiansCircumstances
        : defaultValues.substituteGuardiansCircumstances,
      appointmentTermination: doc.appointmentTermination
        ? doc.appointmentTermination
        : defaultValues.appointmentTermination,
      functionsAuthorisedAll: doc.functionsAuthorisedAll
        ? doc.functionsAuthorisedAll
        : defaultValues.functionsAuthorisedAll,
      functions: doc.functions ? doc.functions : defaultValues.functions,
      circumstances: doc.circumstances
        ? doc.circumstances
        : defaultValues.circumstances,
      directions: doc.directions ? doc.directions : defaultValues.directions,
      advanceHealthDirective:
        doc.advanceHealthDirective === true ||
        doc.advanceHealthDirective === false
          ? doc.advanceHealthDirective
          : defaultValues.advanceHealthDirective,
    }
  },
}

const actions = {}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
