import { db } from '../../../../plugins/firebase'

const getters = {
  will_funeral_wishes: ({ data }, getters) => {
    const wishes = data?.wishes || {}
    const value = {
      remains: wishes.remains || null,
      message: wishes.message || ''
    }

    const selectedOption = value.remains
      ? getters.lists_group_option('remains', value.remains)
      : null

    return {
      value: value,
      selectedOption: selectedOption,
      isComplete: value.message.length > 0 && value.remains
    }
  }
}

const actions = {
  async save_will_funeral_wishes({ getters }, data) {
    const { uid } = getters.auth_user
    const docRef = db.collection('usersV2').doc(uid)

    return docRef.set(
      {
        wishes: data
      },
      { merge: true }
    )
  }
}

const mutations = {}

export default {
  getters,
  actions,
  mutations
}
