import Vue from "vue"

import { auth } from "../../../plugins/firebase"

const getters = {
  auth_user: ({ auth }) => {
    return auth
      ? {
          ...auth,
          token: auth.uid?.slice(0, 8).toUpperCase(),
        }
      : {}
  },
  auth_registration_data: ({ registrationData }) => {
    return registrationData
  },
}

const actions = {
  auth_update_email: async ({ getters, commit }, { password, email }) => {
    const { auth, functions, fb } = await import("@/plugins/firebase")

    let credentials = fb.auth.EmailAuthProvider.credential(
      getters["auth_user"].email,
      password
    )

    return auth.currentUser
      .reauthenticateWithCredential(credentials)
      .then(() => {
        const updateEmail = functions.httpsCallable("clientChangeEmail")
        return updateEmail({ uid: getters["auth_user"].uid, email: email })
          .then(() => {
            const newCredentials = fb.auth.EmailAuthProvider.credential(
              email,
              password
            )
            return auth.currentUser.reauthenticateWithCredential(newCredentials)
          })
          .then((_auth) => {
            commit("SET_AUTH", {
              email: _auth.user.email,
              uid: _auth.user.uid,
              emailVerified: _auth.user.emailVerified,
            })
            return {
              message: "Email changed successfully",
              type: "success",
            }
          })
          .catch((err) => {
            return {
              message: err.message
                ? err.message
                : "Error, could not change your email",
              type: "error",
            }
          })
      })
      .catch(() => {
        return {
          message: "Error authenticating user. Incorrect password",
          type: "error",
        }
      })
  },
  auth_change_password: async (
    { getters },
    { currentPassword, newPassword }
  ) => {
    const { auth, fb } = await import("@/plugins/firebase")

    let credentials = fb.auth.EmailAuthProvider.credential(
      getters["auth_user"].email,
      currentPassword
    )
    return auth.currentUser
      .reauthenticateWithCredential(credentials)
      .then(() => {
        return auth.currentUser.updatePassword(newPassword)
      })
      .then(() => {
        return {
          message: "Password changed successfully",
          type: "success",
        }
      })
      .catch(() => {
        return {
          message: "Error authenticating user. Incorrect password",
          type: "error",
        }
      })
  },
  auth_delete_account: async ({ getters }, { password }) => {
    const { auth, fb } = await import("@/plugins/firebase")

    let credentials = fb.auth.EmailAuthProvider.credential(
      getters["auth_user"].email,
      password
    )
    return auth.currentUser
      .reauthenticateWithCredential(credentials)
      .then(() => {
        return auth.currentUser.delete()
      })
      .then(() => {
        return {
          message: "Account deleted successfully",
          type: "success",
        }
      })
      .catch(() => {
        return {
          message: "Error authenticating user. Incorrect password",
          type: "error",
        }
      })
  },
  auth_login: async (_, { email, password }) => {
    const { auth } = await import("@/plugins/firebase")
    return auth.signInWithEmailAndPassword(email, password)
  },
  auth_logout: async ({ dispatch, commit }) => {
    commit("app/SET_DATA_LOADED", false, { root: true })
    return await auth.signOut().then(async () => {
      return await dispatch("set_anon_user", null, { root: true })
    })
  },
  auth_reset_password: async (_, { email }) => {
    const { auth } = await import("@/plugins/firebase")
    return auth.sendPasswordResetEmail(email)
  },
  auth_verify_password_reset_code: async (_, actionCode) => {
    const { auth } = await import("@/plugins/firebase")
    return await auth.verifyPasswordResetCode(actionCode)
  },
  auth_confirm_password_reset: async (_, { actionCode, password }) => {
    const { auth } = await import("@/plugins/firebase")
    return await auth.confirmPasswordReset(actionCode, password)
  },
  auth_register: async (_, { email, password }) => {
    const { auth } = await import("@/plugins/firebase")
    return auth.createUserWithEmailAndPassword(email, password)
  },
  auth_initialise_account: async ({ getters }, uid) => {
    const { db } = await import("@/plugins/firebase")
    const meta = getters.auth_registration_data

    // Get _uc_ cookies
    let ucCookies = document.cookie
      .split("; ")
      .filter((v) => v.substring(0, 4) === "_uc_")

    let tracking = {}
    ucCookies.forEach((v) => {
      let [prop, val] = v.split("=")
      if (prop && val && prop.slice(4).length) {
        tracking[prop.slice(4)] = val
      }
    })

    const profile = {
      access: {
        will: false,
        poa: false,
        expiryDate: {
          day: "",
          month: "",
          year: "",
        },
      },
      address: {
        state: meta?.state || "",
        country: "Australia",
        postcode: "",
        street: "",
        suburb: "",
      },
      beneficiaries: {},
      dob: {
        day: "",
        month: "",
        year: "",
      },
      executors: {
        backup: [],
        primary: [],
        type: "",
        professional: {
          version: "",
          date: null,
        },
      },
      name: {
        first: "",
        middle: "",
        last: "",
      },
      partnerId: "",
      progress: {
        about: false,
        partner: meta["partner"] === "single" ? true : false,
        children: meta["children"] === "no" ? true : false,
        pets: meta["pets"] === "no" ? true : false,
        executors: false,
        gifts: false,
        estate: false,
        wishes: false,
      },
      phone: meta.phone ? meta.phone : "",
      relationshipStatus: meta.partner ? meta.partner : "",
      wishes: {
        remains: "",
        message: "",
      },
    }

    profile.meta = {}

    if (meta.referrer && meta.referrer.id) {
      profile.meta.referrer = meta.referrer
    } else if (tracking.rid && tracking.rid.length) {
      profile.meta.referrer = { id: tracking.rid }
    } else if (
      tracking.utm_medium &&
      tracking.utm_medium === "aff" &&
      tracking.utm_source
    ) {
      profile.meta.referrer = { id: tracking.utm_source }
    }

    if (meta.userData) {
      profile.meta.userData = meta.userData
    }

    if (Object.keys(tracking).length) {
      profile.meta.tracking = tracking
    }

    return db.collection("usersV2").doc(uid).set(profile)
  },
}

const mutations = {
  SET_AUTH(state, { email, uid, emailVerified }) {
    Vue.set(state, "auth", {
      email: email || null,
      uid: uid || null,
      emailVerified: emailVerified || null,
    })
  },
  SET_REGISTRATION_DATA(state, { key, value }) {
    Vue.set(state.registrationData, key, value)
  },
}

export default {
  getters,
  actions,
  mutations,
}
