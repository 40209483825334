import { sa_acd_required } from "../../../../config/poaRequiredFields"
import store from "../../../index"
import entityArrayComplete from "./utils"

const getters = {
  sa_acd_is_valid: ({ vault }) => {
    const doc = vault.find((o) => o.id === "poaSaAcd") || {}
    const people = store.getters["account/entities_people"]
    // if changed, also change in /portal/src/components/areas/manageCustomers/PoaManagement.vue
    if (!doc.substitutes?.length) return false
    if (!entityArrayComplete(doc.substitutes, people, sa_acd_required))
      return false
    return true
  },
  poa_sa_acd: ({ vault }) => {
    const doc = vault.find((o) => o.id === "poaSaAcd") || {}

    const options = {
      substituteAppointment: ["together", "separately"],
    }

    const defaultValues = {
      substitutes: [],
      substituteAppointment: null,
      importantToMe: {
        considerations: null,
        avoidOutcomes: null,
        preferredHealthCare: null,
        whereToLive: null,
        personalArrangements: null,
        dyingWishes: null,
      },
      bindingRefusals: null,
      interpreter: null,
    }

    return {
      substitutes: doc.substitutes
        ? doc.substitutes
        : defaultValues.substitutes,
      substituteAppointment:
        doc.substituteAppointment &&
        options.substituteAppointment.includes(doc.substituteAppointment)
          ? doc.substituteAppointment
          : defaultValues.substituteAppointment,
      importantToMe: doc.importantToMe
        ? {
            considerations: doc.importantToMe.considerations
              ? doc.importantToMe.considerations
              : defaultValues.importantToMe.considerations,
            avoidOutcomes: doc.importantToMe.avoidOutcomes
              ? doc.importantToMe.avoidOutcomes
              : defaultValues.importantToMe.avoidOutcomes,
            preferredHealthCare: doc.importantToMe.preferredHealthCare
              ? doc.importantToMe.preferredHealthCare
              : defaultValues.importantToMe.preferredHealthCare,
            whereToLive: doc.importantToMe.whereToLive
              ? doc.importantToMe.whereToLive
              : defaultValues.importantToMe.whereToLive,
            personalArrangements: doc.importantToMe.personalArrangements
              ? doc.importantToMe.personalArrangements
              : defaultValues.importantToMe.personalArrangements,
            dyingWishes: doc.importantToMe.dyingWishes
              ? doc.importantToMe.dyingWishes
              : defaultValues.importantToMe.dyingWishes,
          }
        : defaultValues.importantToMe,
      bindingRefusals: doc.bindingRefusals
        ? doc.bindingRefusals
        : defaultValues.bindingRefusals,
      interpreter: doc.interpreter
        ? doc.interpreter
        : defaultValues.interpreter,
    }
  },
}

const actions = {}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
