import Vue from 'vue'

import { storage } from '../../../../plugins/firebase'

const getters = {
  entities_messages: (state) => {
    return state.entities.filter((entity) => {
      return entity.type === 'message'
    })
  },
  messages_uploading_file: ({ messagesUploadingFile }) => {
    return messagesUploadingFile
  }
}
const actions = {
  upload_entity_message_file: ({ getters, commit }, { id, file }) => {
    const { uid } = getters.auth_user
    const task = storage.ref().child(uid).child('messages').child(id).put(file)
    return new Promise((resolve, reject) => {
      task.on(
        'state_changed',
        (snapshot) => {
          commit(
            'SET_UPLOADING_FILE',
            Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          )
        },
        (err) => {
          commit('SET_UPLOADING_FILE', -1)
          reject(err)
        },
        () => {
          commit('UNSET_UPLOADING_FILE')
          resolve()
        }
      )
    })
  },
  remove_entity_message_file: ({ getters }, { id }) => {
    const { uid } = getters.auth_user
    return storage
      .ref()
      .child(uid)
      .child('messages')
      .child(id)
      .delete()
      .catch(() => {})
  }
}

const mutations = {
  SET_UPLOADING_FILE(state, percentage) {
    Vue.set(state, 'messagesUploadingFile', percentage)
  },
  UNSET_UPLOADING_FILE(state) {
    Vue.set(state, 'messagesUploadingFile', null)
  }
}

export default {
  getters,
  actions,
  mutations
}
