import Vue from 'vue';
import Vuetify, { VAlert,
  VAvatar,
  VBtn,
  VBtnToggle,
  VIcon,
  VInput,
  VItemGroup,
  VList,
  VListGroup,
  VListItem,
  VListItemGroup,
  VSheet } from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'

import AddIcon from '@/comps/theme/icons/add'
import BriefcaseIcon from '@/comps/theme/icons/briefcase'
import CalendarIcon from '@/comps/theme/icons/calendar'
import CoupleIcon from '@/comps/theme/icons/couple'
import DoneIcon from '@/comps/theme/icons/done'
import EditIcon from '@/comps/theme/icons/edit'
import MedicalIcon from '@/comps/theme/icons/medical'
import MenuIcon from '@/comps/theme/icons/menu'
import PlusIcon from '@/comps/theme/icons/plus'
import PlusbigIcon from '@/comps/theme/icons/plusbig'
import SingleIcon from '@/comps/theme/icons/single'
import TickIcon from '@/comps/theme/icons/tick'

Vue.use(Vuetify, {
  components: {
    VAvatar,
    VAlert,
    VBtn,
    VBtnToggle,
    VIcon,
    VInput,
    VItemGroup,
    VList,
    VListGroup,
    VListItem,
    VListItemGroup,
    VSheet,
  },
  directives: {
    Ripple,
  }
});

const vuetifyOptions = {
  icons: {
    iconfont: 'mdi',
    values: {
      done: {
        component: DoneIcon
      },
      plus: {
        component: PlusIcon
      },
      plusbig: {
        component: PlusbigIcon
      },
      calendar: {
        component: CalendarIcon
      },
      add: {
        component: AddIcon
      },
      single: {
        component: SingleIcon
      },
      couple: {
        component: CoupleIcon
      },
      edit: {
        component: EditIcon
      },
      tick: {
        component: TickIcon
      },
      briefcase: {
        component: BriefcaseIcon
      },
      menu: {
        component: MenuIcon
      },
      medical: {
        component: MedicalIcon
      }
    }
  },
  theme: {
    themes: {
      options: {
        customProperties: true,
      },
      light: {
        primary: '#B682FF',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#E41211',
        info: '#2196F3',
        success: '#008656',
        warning: '#E6A700',
        background: '#FFFFFF',
        orange: '#FF7F0B',
        sand: '#F2EADF',
      },
    },
  },
}

export default new Vuetify(vuetifyOptions);
