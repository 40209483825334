<template>
  <svg height="100%" class="edit-icon" viewBox="0 0 12 12" fill="none">
    <path d="M8.54542 5.99803L3.81927 10.7242C3.78787 10.7556 3.75647 10.7713 3.72507 10.787L1.30704 11.0696C1.25993 11.0853 1.22853 11.0853 1.19713 11.0853C1.11862 11.0853 1.05581 11.0539 0.993009 11.0068C0.914501 10.9283 0.8988 10.8027 0.930203 10.6928L1.21283 8.27474C1.22853 8.24334 1.24423 8.21194 1.27564 8.18053L6.00178 3.45439L8.54542 5.99803ZM10.8378 2.4966L9.50321 1.16197C9.15778 0.83224 8.62393 0.83224 8.2942 1.16197L6.84966 2.60651L9.3933 5.15015L10.8378 3.70561C11.1676 3.37588 11.1676 2.84203 10.8378 2.4966Z" />
  </svg>
</template>

<script>

export default {
  name: 'EditIcon'
}
</script>

<style lang="scss">
  .edit-icon {
    width: auto;
    path {
      fill: currentColor;
    }
  }
</style>
