import moment from "moment"

import { db, functions } from "../../../../plugins/firebase"

function isPast(dateObj) {
  const { day, month, year } = dateObj
  const inputDate = new Date(year, month - 1, day)
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)
  return inputDate < currentDate ? true : false
}

const getters = {
  user_access: ({ data }) => {
    const access = data?.access || {}

    const day = Number(access.expiryDate?.day) || null
    const month = Number(access.expiryDate?.month) || null
    const year = Number(access.expiryDate?.year) || null

    const expTimestamp = moment(`${year}-${month}-${day}`, "YYYY-MM-DD")
    // const dayTime = 60 * 60 * 24 * 1000
    // expDate ? (expDate.getTime() + dayTime) - new Date().getTime() : null
    const expInTime = moment.isMoment(expTimestamp)
      ? expTimestamp._d.getTime() - new Date().getTime()
      : null

    const value = {
      expiryDate: {
        day: expTimestamp?.date() || null,
        month: expTimestamp?.month() + 1 || null,
        year: expTimestamp?.year() || null,
      },
      will: access.will || false,
      poa: access.poa || false,
      subscription:
        access.subscription &&
        ["plan_01", "plan_02"].includes(access.subscription)
          ? access.subscription
          : null,
      witnessing: access.witnessing || false,
    }

    const gracePeriod = expInTime
      ? Math.floor(expInTime / (1000 * 3600 * 24)) + 14
      : null
    return {
      value: value,
      isActive: Boolean(expInTime && expInTime > 0),
      isRenewing:
        Boolean(value.subscription) && Boolean(data?.stripe?.customerId),
      gracePeriod,
      isGracePeriod: Boolean(gracePeriod >= 0),
      hasExpired: expInTime != null && expInTime < 0,
      hasPurchasedWill: value.will,
      hasPurchasedPoa: value.poa,
      hasPurchasedWitnessing: value.witnessing,
      hasPurchased: value.will || value.poa,
      expiresInDays: Math.floor(expInTime / (1000 * 3600 * 24)),
      poaAccessState:
        access.poa !== true
          ? "unpurchased"
          : isPast(access.expiryDate)
          ? "expired"
          : "active",
      expInTime,
    }
  },
}

const actions = {
  async save_user_access({ getters }, data) {
    const { uid } = getters.auth_user
    const docRef = db.collection("usersV2").doc(uid)

    return docRef.set(data, { mergeFields: Object.keys(data) })
  },
  async claim_partner_account({ getters }) {
    const { uid } = getters.auth_user
    const meta = getters.user_meta_data

    if (meta.partner?.token && !meta.partner?.claimed) {
      let doc = await db.collection("partners").doc(meta.partner.token).get()
      if (doc.exists && doc.data().claimed !== true) {
        let data = doc.data()
        data.users.push(uid)
        data.claimed = true
        await db.collection("partners").doc(meta.partner.token).set(data)

        let expiry = new Date().setFullYear(new Date().getFullYear() + 1)
        let access = data.access || { will: true, poa: false }
        await db
          .collection("usersV2")
          .doc(uid)
          .set(
            {
              access: {
                will: access.will,
                poa: access.poa,
                expiryDate: {
                  day: new Date(expiry).getDate(),
                  month: new Date(expiry).getMonth() + 1,
                  year: new Date(expiry).getFullYear(),
                },
              },
              meta: {
                partner: {
                  claimed: true,
                },
              },
            },
            { merge: true }
          )
      }
    }

    return true
  },
  async extend_expiry_date({ getters }, { years, months, days }) {
    const { uid } = getters.auth_user
    const docRef = db.collection("usersV2").doc(uid)

    return docRef.set(
      { years, months, days },
      { mergeFields: ["access.expiryDate"] }
    )
  },
  async update_subscription({ getters }, token) {
    const { uid } = getters.auth_user

    return functions
      .httpsCallable("clientAddSubscription")({
        uid,
        tokenId: token.id,
        planId: getters.user_access.value.subscription,
      })
      .then(() => {
        return {
          message: "Subscription updated",
          type: "success",
        }
      })
  },
  async cancel_subscription({ getters }) {
    const { uid } = getters.auth_user
    return functions
      .httpsCallable("clientCancelSubscription")({
        uid,
      })
      .then(() => {
        return {
          message: "Subscription cancelled",
          type: "success",
        }
      })
  },
}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
