import { db } from '../../../../plugins/firebase'

const getters = {
  vault_wishes: (_, getters) => (grouping) => {
    const wishes = getters.vault('wishes') || {}
    let value = getters.list_group(grouping).reduce((obj, q) => {
      const obj1 = {
        ...obj,
        [q.key]: wishes[grouping]?.[q.key] || (q.options ? null : '')
      }
      if (q.options && q.options.includes('Other'))
        obj1[`${q.key}Other`] = wishes[grouping]?.[`${q.key}Other`] || ''

      return obj1
    }, {})
    return {
      value: value,
      isComplete: !Object.values(value).every(
        (val) => val === null || val.length === 0
      )
    }
  },
  vault_wishes_service: (_, getters) => {
    return getters.vault_wishes('services')
  },
  vault_wishes_memorialisation: (_, getters) => {
    return getters.vault_wishes('memorialisation')
  },
  funeral_prepaid: ({ data }, getters) => {
    const prepaid = data?.wishes?.prepaid || {}
    const value = {
      plan: prepaid.plan || null,
      detail: prepaid.detail || ''
    }

    const selectedOption = value.plan
      ? getters.lists_group_option('prepaidFuneral', value.plan)
      : null

    return {
      value: value,
      selectedOption: selectedOption,
      isComplete: selectedOption?.active || false
    }
  }
}

const actions = {
  async save_funeral_prepaid({ getters }, { plan, detail }) {
    const { uid } = getters.auth_user
    const docRef = db.collection('usersV2').doc(uid)

    const data = {
      prepaid: {
        plan: plan || null,
        detail: detail || ''
      }
    }

    return docRef.set(
      {
        wishes: data
      },
      { merge: true }
    )
  }
}

const mutations = {}

export default {
  getters,
  actions,
  mutations
}
