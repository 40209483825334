import { nsw_eg_required } from "../../../../config/poaRequiredFields"
import store from "../../../index"
import entityArrayComplete from "./utils"

const getters = {
  nsw_eg_is_valid: ({ vault }) => {
    const doc = vault.find((o) => o.id === "poaNswEg") || {}
    const people = store.getters["account/entities_people"]
    // if changed, also change in /portal/src/components/areas/manageCustomers/PoaManagement.vue
    if (!doc.guardians?.length) return false
    if (!entityArrayComplete(doc.guardians, people, nsw_eg_required))
      return false
    if (
      doc.substitute &&
      !entityArrayComplete([doc.substitute], people, nsw_eg_required)
    )
      return false
    if (
      !(
        doc.functions?.livingSituation ||
        doc.functions?.healthCare ||
        doc.functions?.personalServices ||
        doc.functions?.medicalDental ||
        doc.functions?.other
      )
    )
      return false
    return true
  },
  poa_nsw_eg: ({ vault }) => {
    const doc = vault.find((o) => o.id === "poaNswEg") || {}

    const options = {
      appointmentOptions: ["jointly", "severally", "jointly-severally"],
    }

    const defaultValues = {
      guardians: [],
      substitute: null,
      appointment: null,
      appointmentTermination: null,
      functions: {
        livingSituation: null,
        livingSituationDetails: null,
        healthCare: null,
        healthCareDetails: null,
        personalServices: null,
        personalServicesDetails: null,
        medicalDental: null,
        medicalDentalDetails: null,
        other: null,
      },
      directions: null,
    }

    return {
      guardians: doc.guardians ? doc.guardians : defaultValues.guardians,
      substitute: doc.substitute ? doc.substitute : defaultValues.substitute,
      appointment:
        doc.appointment && options.appointmentOptions.includes(doc.appointment)
          ? doc.appointment
          : defaultValues.appointment,
      appointmentTermination: doc.appointmentTermination
        ? doc.appointmentTermination
        : defaultValues.appointmentTermination,
      functions: doc.functions
        ? {
            livingSituation: doc.functions.livingSituation
              ? doc.functions.livingSituation
              : defaultValues.functions.livingSituation,
            livingSituationDetails: doc.functions.livingSituationDetails
              ? doc.functions.livingSituationDetails
              : defaultValues.functions.livingSituationDetails,
            healthCare: doc.functions.healthCare
              ? doc.functions.healthCare
              : defaultValues.functions.healthCare,
            healthCareDetails: doc.functions.healthCareDetails
              ? doc.functions.healthCareDetails
              : defaultValues.functions.healthCareDetails,
            personalServices: doc.functions.personalServices
              ? doc.functions.personalServices
              : defaultValues.functions.personalServices,
            personalServicesDetails: doc.functions.personalServicesDetails
              ? doc.functions.personalServicesDetails
              : defaultValues.functions.personalServicesDetails,
            medicalDental: doc.functions.medicalDental
              ? doc.functions.medicalDental
              : defaultValues.functions.medicalDental,
            medicalDentalDetails: doc.functions.medicalDentalDetails
              ? doc.functions.medicalDentalDetails
              : defaultValues.functions.medicalDentalDetails,
            other: doc.functions.other
              ? doc.functions.other
              : defaultValues.functions.other,
          }
        : defaultValues.functions,
      directions: doc.directions ? doc.directions : defaultValues.directions,
    }
  },
}

const actions = {}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
