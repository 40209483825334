import "./sass/poa.scss"
import "./plugins/perfectScrollbar"
import "./plugins/globalComponents"
import "./plugins/mixinsComponentDefaults"

import Vue from "vue"
import VueGtm from "vue-gtm"
import LoadScript from "vue-plugin-load-script"

import App from "./App.vue"
import { auth, logout } from "./plugins/firebase"
import vuetify from "./plugins/vuetify"
import router from "./router"
import store from "./store"

Vue.use(LoadScript)
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,
  enabled: process.env.VUE_APP_GTM_ENABLE === "true",
  debug: false,
  loadScript: true,
  vueRouter: router,
})

import globalFunctions from "./plugins/globalFunctions"
Vue.use(globalFunctions)

import { StripePlugin } from "@vue-stripe/vue-stripe"

const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
}

Vue.use(StripePlugin, options)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const requiresAnon = to.matched.some((record) => record.meta.requiresAnon)
  const isAuthenticated = auth.currentUser

  //
  if (
    to.query.rid &&
    typeof to.query.rid === "string" &&
    to.query.rid.length <= 32
  ) {
    let referrer = { id: to.query.rid }
    if (
      to.query.rtok &&
      typeof to.query.rtok === "string" &&
      to.query.rtok.length <= 32
    ) {
      referrer.token = to.query.rtok
    }
    store.commit("cacheReferrer", referrer)
  }

  if (to.query.ptok && isAuthenticated) {
    logout().then(() => {
      next({
        path: "/get-started",
        query: {
          ptok: to.query.ptok,
        },
      })
    })
  }

  if (requiresAuth && !isAuthenticated) {
    next("/login")
  } else if (requiresAnon && isAuthenticated) {
    next("/")
  } else {
    next()
  }
})

router.afterEach(() => {
  setTimeout(() => {
    store.commit("app/SET_PAGE_LOADED", true)
  }, 500)
})

let app

auth.onAuthStateChanged(async (user) => {
  store.commit("app/SET_DATA_LOADED", false)
  if (user) {
    await store.dispatch("bind_data", { auth: user })

    let access = store.getters["account/user_access"]

    // Load 'partners' doc if meta.partnerToken exists in user, and user hasn't already made purchase
    if (!access.hasPurchased) {
      let partnerToken = store.getters["anon/getPartnerToken"]
      if (partnerToken) {
        await store.dispatch("account/save_user_data_deep", {
          meta: {
            partnerToken: store.state.anon.partnerToken,
          },
        })
        store.commit("anon/SET_PARTNER_TOKEN", null)
      }

      let meta = store.getters["account/user_meta_data"]
      if (meta.partnerToken) {
        await store.dispatch("bindPartnerPurchase", meta.partnerToken)
      }
    }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "userAuthenticated",
      userEmail: user.email,
      userId: user.uid,
      userFirstName: store.getters["account/user_name"].value.first,
      userLastName: store.getters["account/user_name"].value.last,
      userPhoneNumber: store.getters["account/user_phone"].value,
      userAddress: store.getters["account/user_address"].displayAddress,
      userDob: store.getters["account/user_dob"].value,
      userRelationshipStatus:
        store.getters["account/user_partner"].value.relationshipStatus || "",
    })
  } else {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "userUnauthenticated",
    })
    store.commit("account/SET_AUTH", {})
    setTimeout(() => {
      store.commit("app/SET_DATA_LOADED", true)
    }, 500)
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app")
  }
})
