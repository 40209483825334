<template>
  <svg height="100%" class="case-icon" viewBox="0 0 19 18" fill="none">
    <path d="M17.774 5.76491C17.6619 5.22334 17.1764 4.84985 16.6162 4.84985H14.2445V2.19806C14.2445 1.61915 13.8336 1.13361 13.2734 1.02156C12.0222 0.778795 10.7337 0.666748 9.46379 0.666748C8.17525 0.666748 6.90538 0.778795 5.65418 1.02156C5.07527 1.13361 4.6831 1.61915 4.6831 2.19806V4.84985H2.31143C1.7512 4.84985 1.26566 5.22334 1.13494 5.76491C0.724095 7.55766 0.5 9.33175 0.5 11.1245C0.5 12.8986 0.724095 14.71 1.13494 16.5401C1.24698 17.0257 1.63915 17.3805 2.12469 17.4552C4.55238 17.81 7.01743 17.9967 9.46379 17.9967C11.9102 17.9967 14.3752 17.81 16.8029 17.4552C17.2884 17.3805 17.6806 17.0257 17.7927 16.5401C18.2035 14.71 18.4276 12.8986 18.4276 11.1245C18.4276 9.33175 18.2035 7.55766 17.774 5.76491ZM7.07345 3.15047C8.86621 2.94504 10.0614 2.94504 11.8541 3.15047V4.84985H7.07345V3.15047Z" />
  </svg>

</template>

<script>

  export default {
    name: 'BriefcaseIcon'
  }
</script>

<style lang="scss">
  .case-icon {
    width: auto;
    path {
      fill: currentColor
    }
  }
</style>
