import { tas_epa_required } from "../../../../config/poaRequiredFields"
import store from "../../../index"
import entityArrayComplete from "./utils"

const getters = {
  tas_epa_is_valid: ({ vault }) => {
    const doc = vault.find((o) => o.id === "poaTasEpa") || {}
    const people = store.getters["account/entities_people"]
    // if changed, also change in /portal/src/components/areas/manageCustomers/PoaManagement.vue
    if (!doc.attorneys?.length) return false
    if (!entityArrayComplete(doc.attorneys, people, tas_epa_required))
      return false
    return true
  },
  poa_tas_epa: ({ vault }) => {
    const doc = vault.find((o) => o.id === "poaTasEpa") || {}

    const options = {
      appointmentOptions: ["jointly", "jointly-and-severally"],
    }

    const defaultValues = {
      attorneys: [],
      appointment: null,
    }

    return {
      attorneys: doc.attorneys ? doc.attorneys : defaultValues.attorneys,
      appointment:
        doc.appointment && options.appointmentOptions.includes(doc.appointment)
          ? doc.appointment
          : defaultValues.appointment,
    }
  },
}

const actions = {}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
