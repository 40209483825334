import { db } from "../../../../plugins/firebase"

const getters = {
  user_meta_data: ({ data }) => {
    return data?.meta || {}
  },
}

const actions = {
  async save_user_data({ getters }, data) {
    const { uid, email } = getters.auth_user
    const docRef = db.collection("usersV2").doc(uid)
    await docRef.set(data, { mergeFields: Object.keys(data) })

    // Trigger profile update event
    if (
      Object.keys(data).some((k) => {
        return [
          "name",
          "dob",
          "address",
          "phone",
          "alternateEmail",
          "occupation",
          "relationshipStatus",
        ].includes(k)
      })
    ) {
      let name = getters.user_name.value
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "userUpdated",
        userEmail: email,
        userId: uid,
        userFirstName: name.first,
        userLastName: name.last,
        userPhoneNumber: getters.user_phone.value,
        userAlternateEmail: getters.user_alternate_email.value,
        userOccupation: getters.user_occupation.value,
        userAddress: getters.user_address.displayAddress,
        userDob: getters.user_dob.value,
        userRelationshipStatus: getters.user_partner.value.relationshipStatus,
      })
    }

    return true
  },
  async save_user_data_deep({ getters }, data) {
    const { uid } = getters.auth_user
    const docRef = db.collection("usersV2").doc(uid)

    return docRef.set(data, { merge: true })
  },
}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
