import { db, serverTimestamp } from '../../../../plugins/firebase'

const getters = {
  entities_charities: ({ entities }) => {
    return entities.filter((entity) => {
      return entity.type === 'charity'
    })
  },
  entity_charity:
    ({ entities }) =>
    (abn = null) => {
      let value = {
        abn: '',
        name: '',
        type: 'charity'
      }
      if (abn) {
        const charities = entities.filter((entity) => {
          return entity.type === 'charity' && Number(entity.abn) === Number(abn)
        })
        value = charities[0] || value
      }

      return value
    }
}

const actions = {
  async save_entity_charity({ getters }, entity) {
    const find = getters.entity_charity(entity.abn)
    if (find.id) {
      return find.id
    }

    const { uid } = getters.auth_user
    const charityId = db.collection('usersV2').doc(uid).collection('entities').doc().id
    const docRef = db.collection('usersV2').doc(uid).collection('entities').doc(charityId)

    const data = {
      ...entity,
      type: 'charity',
      createdAt: serverTimestamp
    }

    await docRef.set(data, { merge: true })

    return charityId
  }
}

const mutations = {}

export default {
  getters,
  actions,
  mutations
}
