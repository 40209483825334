import Vue from 'vue'

const getters = {
  is_data_loaded: ({ dataLoaded }) => {
    return Boolean(dataLoaded)
  },
  is_page_loaded: ({ pageLoaded }) => {
    return Boolean(pageLoaded)
  },
  is_app_loaded: ({ dataLoaded, pageLoaded }) => {
    return dataLoaded && pageLoaded
  },
  app_message: ({ message }) => {
    return message
  },
  app_faq: ({ faq }) => {
    return faq
  }
}

const actions = {}

const mutations = {
  SET_DATA_LOADED(state, val) {
    Vue.set(state, 'dataLoaded', Boolean(val))
  },
  SET_PAGE_LOADED(state, val) {
    Vue.set(state, 'pageLoaded', Boolean(val))
  },
  SET_MESSAGE(state, { text, type, time }) {
    Vue.set(state, 'message', { text, type, time: time || 4000 })
  },
  UNSET_MESSAGE(state) {
    Vue.set(state, 'message', {})
  },
  SET_FAQ(state, { question, answer }) {
    Vue.set(state, 'faq', { question, answer })
  },
  UNSET_FAQ(state) {
    Vue.set(state, 'faq', null)
  }
}

export default {
  getters,
  actions,
  mutations
}
