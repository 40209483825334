import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/functions"
import "firebase/remote-config"

import firebase from "firebase/app"
import { forEach } from "lodash"

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
}

firebase.initializeApp(config)
export const fb = firebase
export const db = firebase.firestore()
db.settings({ experimentalAutoDetectLongPolling: true, merge: true })
export const auth = firebase.auth()
export const storage = firebase.storage()
export const functions = firebase.app().functions("australia-southeast1")
export const timestamp = firebase.firestore.Timestamp
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()
export const remoteConfig = firebase.remoteConfig()
remoteConfig.settings.minimumFetchIntervalMillis = 3600000
remoteConfig.defaultConfig = {
  book_call: false,
}

if (process.env.NODE_ENV === "development") {
  functions.useEmulator("localhost", 5001)
  // db.useEmulator("localhost", 8025)
  // storage.useEmulator("localhost", 9199)
  // auth.useEmulator("http://localhost:9099")
}

export const charitiesDoc = db.doc("metadata/charities")
export const pricingRef = db.doc("metadata/pricing")
export const settingsRef = db.doc("metadata/appSettings")
export const referrersCol = db.collection("referrers")
export const usersCol = db.collection("usersV2")

/**
 * Functions
 */
export const download_will = (uid) => {
  var willPdfGenerate = functions.httpsCallable("willPdfGenerate3")

  return willPdfGenerate({ uid: uid })
}

export const generate_will = (uid) => {
  var willGenerate = functions.httpsCallable("documentsGenerateWill")

  return willGenerate({ uid: uid })
}

export const mailchimp_add_tag = (email, tag) => {
  var webhookMailchimpAddTag = functions.httpsCallable("webhookMailchimpAddTag")

  return webhookMailchimpAddTag({ email: email, tag: tag })
}

export const mailchimp_update_contact = (contact) => {
  var webhookMailchimpUpdateContact = functions.httpsCallable(
    "webhookMailchimpUpdateContact"
  )

  return webhookMailchimpUpdateContact(contact)
}

export const stripe_charge = (uid, token, amount, email) => {
  var stripeCharge = functions.httpsCallable("webhookStripeCharge")

  return stripeCharge({ uid: uid, token: token, amount: amount, email: email })
}

export const referrer_set_id = (uid, token) => {
  var referrerSetId = functions.httpsCallable("webhookReferrerSetId")

  return referrerSetId({ token: token, uid: uid })
}

/**
 * Storage
 */
export const add_message = (uid, object) => {
  let colRef = db.collection(DB_COLLECTION).doc(uid).collection("entities")
  let message = {
    title: object.title,
    message: object.message,
    recipient: object.recipient,
    file: {
      name: object.file.name,
      size: object.file.size,
      type: object.file.type,
    },
    type: "message",
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  }
  return colRef.add(message)
}

export const update_message = (uid, entity_id, object) => {
  let docRef = db
    .collection(DB_COLLECTION)
    .doc(uid)
    .collection("entities")
    .doc(entity_id)

  let message = {
    title: object.title,
    message: object.message,
    recipient: object.recipient,
    file: {
      name: object.file.name,
      size: object.file.size,
      type: object.file.type,
    },
  }

  return docRef.set(message, { merge: true })
}

/**
 * Database functions
 */
const DB_COLLECTION = "usersV2"

/**
 * Utilities: promo codes
 */
export const get_promo_codes = () => {
  let docRef = db.collection("utilityDocs").doc("promoCodes")

  return docRef.get()
}

/**
 * Utilities: charity partners
 */
export const get_charity_partners = () => {
  let docRef = db.collection("utilityDocs").doc("charityPartners")

  return docRef.get()
}

/**
 * Utilities: partner account
 */
export const activate_partner_account = async (uid, token) => {
  let docRef = db.collection("partners").doc(token)

  let result = false

  await docRef.get().then((doc) => {
    if (doc.exists) {
      let record = doc.data()

      if (!record.claimed) {
        record.claimed = true
        record.users.push(uid)
        docRef.set(record).then(() => {
          update_access(uid, true)
        })

        result = true
      }
    }
  })

  return result
}

export const get_prepaid_code = async (code) => {
  let docRef = db.collection("prepaid").doc(code)
  let result = null

  await docRef.get().then((doc) => {
    if (doc.exists) {
      result = doc.data()
    }
  })

  return result
}

export const update_prepaid_code = async (uid, code) => {
  await db.collection("prepaid").doc(code).set(
    {
      uid: uid,
      active: false,
      redeemedAt: firebase.firestore.FieldValue.serverTimestamp(),
    },
    { merge: true }
  )

  return
}

export const activate_prepaid_account = async (uid, code) => {
  let docRef = db.collection("prepaid").where("code", "==", code).limit(1)

  let result = null

  await docRef.get().then(async (snapshot) => {
    if (!snapshot.empty) {
      let doc = snapshot.docs[0]
      if (doc.data().active) {
        await update_access(uid, true).then(() => {
          db.collection("prepaid").doc(doc.id).set(
            {
              uid: uid,
              active: false,
              redeemedAt: firebase.firestore.FieldValue.serverTimestamp(),
            },
            { merge: true }
          )
          result = "success"
        })
      } else {
        result = "inactive"
      }
    } else {
      result = "missing"
    }
  })

  return result
}
/**
 * Send mail
 */
export const send_mail = (uid, template, emails) => {
  let batch = db.batch()

  forEach(emails, (data, to) => {
    let mail = {
      to: to,
      from: "Willed <do-not-reply@willed.com.au>",
      template: {
        name: template,
        data: data,
      },
      uid: uid,
    }
    batch.set(db.collection("mail").doc(), mail)
  })

  return batch.commit()
}

/**
 *  Registration
 *  - login
 *  - logout
 *  - register
 */
export const login = (email, password) => {
  return auth.signInWithEmailAndPassword(email, password)
}

export const register = (email, password) => {
  return auth.createUserWithEmailAndPassword(email, password)
}

export const initialise_account = (uid, meta) => {
  let profile = {
    access: {
      will: false,
      video: false,
      expiryDate: {
        day: "",
        month: "",
        year: "",
      },
    },
    address: "",
    beneficiaries: {},
    dob: {
      day: "",
      month: "",
      year: "",
    },
    executors: {
      backup: [],
      primary: [],
      type: "",
      professional: {
        version: "",
        date: null,
      },
    },
    name: {
      first: "",
      middle: "",
      last: "",
    },
    partnerId: "",
    progress: {
      about: false,
      partner: false,
      children: false,
      pets: false,
      executors: false,
      gifts: false,
      estate: false,
      wishes: false,
    },
    phone: meta.phone ? meta.phone : "",
    relationshipStatus: meta.partner ? meta.partner : "",
    wishes: {
      remains: "",
      message: "",
    },
  }

  profile.meta = {}

  if (meta.partnerToken) {
    profile.meta.partner = {
      token: meta.partnerToken,
      claimed: false,
    }
  }

  if (meta.referrer && meta.referrer.id) {
    profile.meta.referrer = meta.referrer
  }

  if (meta.userData) {
    profile.meta.userData = meta.userData
  }

  return db.collection(DB_COLLECTION).doc(uid).set(profile)
}

export const logout = () => {
  return auth.signOut()
}

export const update_email = (uid, email) => {
  let updateEmail = functions.httpsCallable("clientChangeEmail")

  return updateEmail({ uid: uid, email: email })
}

export const delete_account = () => {
  return auth.currentUser.delete()
}

export const update_password = (password) => {
  return auth.currentUser.updatePassword(password)
}

export const authenticate_user = (password, email = null) => {
  let userEmail = email ? email : auth.currentUser.email
  let credentials = firebase.auth.EmailAuthProvider.credential(
    userEmail,
    password
  )

  return auth.currentUser.reauthenticateWithCredential(credentials)
}

export const reset_password = (email) => {
  return auth.sendPasswordResetEmail(email)
}

/**
 *  Profile general
 *  - update_profile_fields
 */
export const update_profile_fields = (uid, fields, paths) => {
  let docRef = db.collection(DB_COLLECTION).doc(uid)

  return docRef.set(fields, { mergeFields: paths })
}

/**  Entity general
 *  - delete_entity
 *  - save_entity
 */
export const save_entity = (uid, entity_id, entity) => {
  let docRef = db
    .collection(DB_COLLECTION)
    .doc(uid)
    .collection("entities")
    .doc(entity_id)

  return docRef.set(entity, { merge: true })
}

export const delete_entity = (uid, entity_id) => {
  let docRef = db
    .collection(DB_COLLECTION)
    .doc(uid)
    .collection("entities")
    .doc(entity_id)

  return docRef.delete()
}

export const update_entities = (uid, entities) => {
  let batch = db.batch()

  forEach(entities, (data, id) => {
    batch.set(
      db.collection(DB_COLLECTION).doc(uid).collection("entities").doc(id),
      data,
      { merge: true }
    )
  })

  return batch.commit()
}

/**
 * Charity
 */
export const add_charity = (uid, object) => {
  let colRef = db.collection(DB_COLLECTION).doc(uid).collection("entities")

  let charity = {
    name: object.name,
    abn: Number(object.abn),
    partner: !!object.partner,
    type: "charity",
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  }
  return colRef.add(charity)
}

export const update_partner_charity = (uid, entity_id, notified = true) => {
  let docRef = db
    .collection(DB_COLLECTION)
    .doc(uid)
    .collection("entities")
    .doc(entity_id)

  return docRef.update({ notified: notified })
}

export const save_event = (uid, event, data) => {
  let colRef = db.collection("events")

  return colRef.add({
    data: data,
    event: event,
    uid: uid,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  })
}

/**  Person
 *  - add_person
 */
export const add_person = (uid, object) => {
  let colRef = db.collection(DB_COLLECTION).doc(uid).collection("entities")
  let person = {
    name: object.name !== undefined ? object.name : "",
    address: object.address !== undefined ? object.address : "",
    underAge: object.underAge !== undefined ? object.underAge : true,
    type: "person",
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  }
  return colRef.add(person)
}

export const update_person = (uid, entity_id, entity) => {
  let docRef = db
    .collection(DB_COLLECTION)
    .doc(uid)
    .collection("entities")
    .doc(entity_id)

  let person = {
    name: entity.name,
    address: entity.address,
    underAge: entity.underAge,
  }

  return docRef.set(person, { merge: true })
}

/**  Gift
 *  - add_gift
 *  - update_gift
 */
export const add_gift = (uid, object) => {
  let colRef = db.collection(DB_COLLECTION).doc(uid).collection("entities")

  let gift = {
    item: object.item,
    type: "gift",
    giftType: object.giftType,
    message: object.message,
    recipient: object.recipient,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  }

  return colRef.add(gift)
}

export const update_gift = (uid, entity_id, entity) => {
  let docRef = db
    .collection(DB_COLLECTION)
    .doc(uid)
    .collection("entities")
    .doc(entity_id)

  let gift = {
    giftType: entity.giftType,
    item: entity.item,
    message: entity.message,
    recipient: entity.recipient,
  }

  return docRef.set(gift, { merge: true })
}

/**  Pet
 *  - add_pet
 *  - update_pet
 */
export const add_pet = (uid, object) => {
  let colRef = db.collection(DB_COLLECTION).doc(uid).collection("entities")

  let pet = {
    name: object.name,
    type: "pet",
    petType: object.petType,
    guardian: object.guardian,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    maintenance:
      object.maintenance && object.maintenance !== "$0"
        ? object.maintenance
        : null,
  }

  return colRef.add(pet)
}

export const update_pet = (uid, entity_id, entity) => {
  let docRef = db
    .collection(DB_COLLECTION)
    .doc(uid)
    .collection("entities")
    .doc(entity_id)

  let pet = {
    name: entity.name,
    petType: entity.petType,
    guardian: entity.guardian,
    maintenance:
      entity.maintenance && entity.maintenance !== "$0"
        ? entity.maintenance
        : null,
  }

  return docRef.set(pet, { merge: true })
}

/**  Children
 *  - unset_child
 *  - set_child
 */
export const add_child = (uid, entity) => {
  let colRef = db.collection(DB_COLLECTION).doc(uid).collection("entities")

  let child = {
    name: entity.name,
    type: "person",
    address: entity.address,
    underAge: entity.underAge,
    guardians: entity.underAge ? entity.guardians : [],
    isChild: true,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  }

  return colRef.add(child)
}

export const update_child = (uid, entity_id, entity) => {
  let docRef = db
    .collection(DB_COLLECTION)
    .doc(uid)
    .collection("entities")
    .doc(entity_id)

  let child = {
    name: entity.name,
    address: entity.address,
    underAge: entity.underAge,
    guardians: entity.underAge ? entity.guardians : [],
  }

  return docRef.set(child, { merge: true })
}

export const unset_child = (uid, entity_id) => {
  let docRef = db
    .collection(DB_COLLECTION)
    .doc(uid)
    .collection("entities")
    .doc(entity_id)

  return docRef.set(
    {
      isChild: firebase.firestore.FieldValue.delete(),
      guardians: firebase.firestore.FieldValue.delete(),
    },
    { mergeFields: ["isChild", "guardians"] }
  )
}

/**
 * Save referrer
 */
export const save_referrer_data = (uid, data) => {
  let docRef = db.collection(DB_COLLECTION).doc(uid)

  let values = {
    id: data.id,
  }

  let paths = ["meta.referrer.id"]

  if (data.token) {
    values.token = data.token
    paths.push("meta.referrer.token")
  }

  return docRef.set(values, { mergeFields: paths })
}

/**
 * Share data with referrers
 */
export const save_share_with_charity = (uid, val) => {
  let docRef = db.collection(DB_COLLECTION).doc(uid)

  return docRef.set(
    {
      meta: {
        shareWithCharities: !!val,
      },
    },
    { merge: true }
  )
}

/** Executors
 *  - unset_backup_executor
 */

/**
 * Payment/Access
 */

export const update_access = async (uid, partner = false, options = {}) => {
  let docRef = db.collection(DB_COLLECTION).doc(uid)

  let expiry = new Date().setFullYear(new Date().getFullYear() + 1)

  let values = {
    access: {
      video: true,
      will: true,
      expiryDate: {
        day: new Date(expiry).getDate(),
        month: new Date(expiry).getMonth() + 1,
        year: new Date(expiry).getFullYear(),
      },
    },
  }

  let paths = [
    "access.video",
    "access.will",
    "access.expiryDate.day",
    "access.expiryDate.month",
    "access.expiryDate.year",
  ]

  if (partner) {
    values.meta = { partner: { claimed: true } }
    paths.push("meta.partner.claimed")
  }

  await docRef.set(values, { mergeFields: paths })

  if (options.prepaidCode) {
    await update_prepaid_code(uid, options.prepaidCode)
  }

  return true
}
